import { STRIPE_KEY } from "config/config";
//CSS
import "pages/public/profile/pages/configuracoes/payment/payment.css";
//REACT LIB
import { useEffect, useState } from "react";
//STRIPE STUFF
import "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
//OTHER LIBS
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//CONTEXT
import { useClientesData } from "pages/public/profile/context/PublicClientesDataContext";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_KEY);
  }
  return stripePromise;
};

export default function Payment() {

  const { qtdFotos, getPaymentInfos, dataVencimento, pagClient, idCliente } =
    useClientesData();

  const [isLoading, setIsLoading] = useState(false);
  const [fotoStatus, setFotoStatus] = useState(false);
  const [velaStatus, setVelaStatus] = useState(false);
  const [planoStatus, setPlanoStatus] = useState(false);
  const [vasoStatus, setVasoStatus] = useState(false);

  useEffect(() => {
    getPaymentInfos();
    localStorage.removeItem("FOTO_STATUS");
    localStorage.removeItem("VELA_STATUS");
    localStorage.removeItem("VASO_STATUS");
    localStorage.removeItem("PLANO_STATUS");
    localStorage.removeItem("ID_CLIENTE");
  }, []);

  //<--STRIPE STUFF

  const redirectToCheckout = async (e) => {
    e.preventDefault();

    const vela = {
      price: "price_1LOO9vHxC80raz6yLakLF5Kc",
      quantity: 1,
    };
    const foto = {
      price: "price_1LOOBIHxC80raz6yD0GoMwqT",
      quantity: 1,
    };
    const vaso = {
      price: "price_1LONqGHxC80raz6y3e9xsz3K",
      quantity: 1,
    };
    const plano = {
      price: "price_1LOOCCHxC80raz6yYV8QFAKP",
      quantity: 1,
    };
    const item = [];

    localStorage.setItem("ID_CLIENTE", idCliente);

    function manipItem() {
      if (fotoStatus === true) {
        item.push(foto);
        localStorage.setItem("FOTO_STATUS", "true");
      }
      if (velaStatus === true) {
        item.push(vela);
        localStorage.setItem("VELA_STATUS", "true");
      }
      if (planoStatus === true) {
        item.push(plano);
        localStorage.setItem("PLANO_STATUS", "true");
      }
      if (vasoStatus === true) {
        item.push(vaso);
        localStorage.setItem("VASO_STATUS", "true");
      }
    }

    setIsLoading(true);
    manipItem();

    const checkoutOptions = {
      lineItems: item,
      mode: "payment",
      successUrl: `${window.location.origin}/profile/${idCliente}/success`,
      cancelUrl: `${window.location.origin}/profile/${idCliente}/cancel`,
    };

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);

    if (error) {
      console.error("strip checkout error", error);
    }
    setIsLoading(false);
  };

  //-->STRIPE STUFF

  return (
    <>
   
    <div className="boxProfilePayment">
      <div className="paymentRodape">
        <div>
          <span>Data de Vencimento: </span>
          {dataVencimento}
        </div>
        <div>
          <span>Fotos do Plano: </span>
          {qtdFotos}
        </div>
      </div>

      <hr className="hrNoMarging" />

      <Form onSubmit={redirectToCheckout}>
        <h5>Para o seu plano:</h5>

        <Row>
          <Col xs="auto" className="my-1">
            <Form.Check
              type="switch"
              id="fotos-switch"
              label="Adicionar 2 fotos"
              onClick={(e) => {
                setFotoStatus(e.target.checked);
              }}
            />
          </Col>
          <Col xs="auto">
            <InputGroup.Text className="textMoney ">
              R$ 19,00
            </InputGroup.Text>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="my-1">
            <Form.Check
              type="switch"
              id="plano-switch"
              label="Estender plano por mais 1 ano"
              onClick={(e) => {
                setPlanoStatus(e.target.checked);
              }}
            />
          </Col>
          <Col xs="auto">
            <InputGroup.Text className="textMoney">R$ 89,00 </InputGroup.Text>
          </Col>
        </Row>

        <h5>Aba homenagem:</h5>

        <Row>
          <Col xs="auto" className="my-1">
            <Form.Check
              type="switch"
              id="vela-switch"
              label="Adicionar animação para vela"
              onClick={(e) => {
                setVelaStatus(e.target.checked);
              }}
            />
          </Col>
          <Col xs="auto">
            <InputGroup.Text className="textMoney">R$ 03,00 </InputGroup.Text>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="my-1">
            <Form.Check
              type="switch"
              id="vaso-switch"
              label="Adicionar flores para o vaso"
              onClick={(e) => {
                setVasoStatus(e.target.checked);
              }}
            />
          </Col>
          <Col xs="auto">
            <InputGroup.Text className="textMoney">R$ 04,00 </InputGroup.Text>
          </Col>
        </Row>
        <br />
        <hr className="hrNoMarging" />
        <div className="textObjetos">
          <span >
           Ao acender uma vela simbolizamos a eternidade, onde independente do tempo as lembras e o amor serão para sempre. 
          </span>
          <span >
            Já as flores têm o significado de beleza, frescor e cor, provando o ciclo da vida. 
          </span>
          <span >
            Homenageá-los e uma formas de mantermos sempre em nossas lembranças. 
          </span>
        </div>
        <br />
        <hr className="hrNoMarging" />
        <div className="btnPaymentContainer">
          <a href={pagClient}>
            <Button variant="primary" className="buttonPayment">
              Voltar para página
            </Button>
          </a>
          <Button variant="primary" type="submit" className="buttonPayment">
            {isLoading ? "Redirecionando..." : "Comprar"}
          </Button>
        </div>
      </Form>
    </div>
    </>
  );
};