//REACT LIB
import {createContext, useContext, useState} from 'react'
//OTHER LIBS
import Alert from 'react-bootstrap/Alert'
//FIREBASE
import { storage, db  } from 'config/Firebase-config';
import { 
    query, 
    getDocs, 
    getDoc,
    where, 
    doc, 
    updateDoc, 
    arrayUnion, 
    arrayRemove, 
    collection } from "firebase/firestore";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject } from "firebase/storage";
//PAGES    
import Homenagem from 'pages/public/profile/pages/homenagem/Homenagem';    


//<--CONTEXT HOOK
const clientesData = createContext();
function useClientesData(){
    return useContext(clientesData);
};
//-->CONTEXT HOOK

//<--PROVIDER
function ClientesDataContextProvider({children}) {

    //<--STATES
    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [idCliente, setIdCliente] = useState("");
    const [conteudo, setConteudo] = useState(<Homenagem />);
    const [pagAtual, setPagAtual] = useState("");
    const [alertContent, setAlertContent] = useState(null);
    const [qtdFotos, setQtdFotos] = useState(0);
    const [dataVencimento, setDataVencimento] = useState(null);
    const [paymentPage, setPaymentPage] = useState("");
    const [pagClient, setPagClient] = useState("");
    const [messageCarousel, setMessageCarousel] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [vasoVisability, setVasoVisability] = useState(false);
    const [velaVisability, setVelaVisability] = useState(false);
    
    //<--OTHER VARS
    const clientesRef = collection(db, "clientes");

    //<--FUNCTIONS
    const getInfos = () => {
        console.log("Peguei info de um cliente especifico")
            
        const q = query(clientesRef, where("linkQrCode", "==", window.location.href ));
        getDocs(q).then((data) => {
            
            const dadosUser = (data.docs.map((doc) => ({...doc.data(), id: doc.id})));
                setIdCliente(dadosUser[0].id);
                setImageUrls(dadosUser[0].urlFotos)
                setQtdFotos(dadosUser[0].qtdFotos)
                setDataVencimento(dadosUser[0].dataVencimento)
                setPaymentPage(dadosUser[0].linkQrCode + "/payment")
                setMessageCarousel(dadosUser[0].message)
                setVasoVisability(dadosUser[0].homenagem.vaso.ativo)
                setVelaVisability(dadosUser[0].homenagem.vela.ativo)

                if (dadosUser[0].firstAccess === true) {
                    const userDoc = doc(db, "clientes", dadosUser[0].id)
                    Date.prototype.addDays = function(days) {
                        var date = new Date(this.valueOf());
                        date.setDate(date.getDate() + days);
                        return date;
                    }
                    const dataAtual = new Date();
                    let vencimento = dataAtual.addDays(366);
                    let vencimentoHomenagem = dataAtual.addDays(5);

                    async function updateStatus (){
                        await updateDoc(userDoc, {
                            firstAccess: false,
                            dataVencimento: vencimento,
                            "homenagem.vaso.ativo": true,
                            "homenagem.vaso.dataVencimento": vencimentoHomenagem,
                            "homenagem.vela.ativo": true,
                            "homenagem.vela.dataVencimento": vencimentoHomenagem
                        })
                        
                        
                    };
                    updateStatus();
                }
                
                async function updateStatus (){
                    const userDoc = doc(db, "clientes", dadosUser[0].id)
                    await updateDoc(userDoc, {paymentLink: dadosUser[0].linkQrCode + "/payment"})
                };  

                updateStatus();    

                if (dadosUser[0].active === false) {    //Sistema de segurança ( se precisar desativar um user por algum motivo)
                    window.location.replace(`${window.location.href}/payment`);
                
                } else if (dadosUser[0].dataVencimento.toDate() < new Date()) { 
                    const userDoc = doc(db, "clientes", dadosUser[0].id);
                    async function updateStatus (){
                        await updateDoc(userDoc, {active: false})
                        window.location.replace(`${window.location.href}/payment`);
                    };
                    updateStatus();
                }; 
                if (dadosUser[0].homenagem.vaso.dataVencimento.toDate() < new Date()) { 
                    const userDoc = doc(db, "clientes", dadosUser[0].id);
                    async function updateStatus (){
                        await updateDoc(userDoc, {"homenagem.vaso.ativo": false});
                        setVasoVisability(false);
                    };
                    updateStatus();
                }else{
                    const userDoc = doc(db, "clientes", dadosUser[0].id);
                    async function updateStatus (){
                        await updateDoc(userDoc, {"homenagem.vaso.ativo": true});
                        setVasoVisability(true);
                    };
                    updateStatus();
                }; 
                if (dadosUser[0].homenagem.vela.dataVencimento.toDate() < new Date()) { 
                    const userDoc = doc(db, "clientes", dadosUser[0].id);
                    async function updateStatus (){
                        await updateDoc(userDoc, {"homenagem.vela.ativo": false});
                        setVelaVisability(false);
                    }
                    updateStatus();
                }else{
                    const userDoc = doc(db, "clientes", dadosUser[0].id);
                    async function updateStatus (){
                        await updateDoc(userDoc, {"homenagem.vela.ativo": true});
                        setVelaVisability(true);
                    }
                    updateStatus();
                };

        }).catch((error) => {
            console.log(error)
        });
    };

    const getPaymentInfos = () => {
        console.log("Peguei info de um cliente especifico para pagamento")
            
        const q = query(clientesRef, where("paymentLink", "==", window.location.href ));
        getDocs(q).then((data) => {
            const dadosUser = (data.docs.map((doc) => ({...doc.data(), id: doc.id})));
                setIdCliente(dadosUser[0].id);
                setQtdFotos(dadosUser[0].qtdFotos);
                setDataVencimento(dadosUser[0].dataVencimento.toDate().toLocaleDateString('pt-BR'));
                setPagClient(dadosUser[0].linkQrCode);
                
        }).catch((error) => {
            console.log(error)
        });
    };

    const uploadFile = (e) => {

        setAlertContent("");

        if (imageUpload == null) {
            setAlertContent(
                <Alert variant="warning">
                    Selecione uma imagem
                </Alert>
            );
            return;

        } else if (imageUrls.length >= qtdFotos) {
            setAlertContent(
                <Alert variant="danger"> 
                    Você chegou ao limite de fotos. Se quiser adicionar mais 
                    <Alert.Link href={paymentPage}> clique aqui</Alert.Link>
                </Alert>
            );
            return;

        }else if (imageUrls.map((name) => { return name.name }).includes(imageUpload.name)) {
            setAlertContent(
                <Alert variant="warning">
                    Você já adicionou essa foto
                </Alert>
            );
        return;
        }; 
          
        const imagePath = ref(storage, `images/${idCliente}/${imageUpload.name}`);
        const userDoc = doc(db, "clientes", idCliente)
            uploadBytes(imagePath, imageUpload).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    updateDoc(userDoc, {
                        urlFotos: arrayUnion({name: imageUpload.name, urls: url})
                        }).then(() => {
                            setImageUrls([...imageUrls, {name: imageUpload.name, urls: url}]);
                        });
                });
                alert("Imagem enviada com sucesso!");
                setImageUpload(null);
                setAlertContent(null);
            });
    };

    const deleteImage = (name, url) => {
        
        const userDoc = doc(db, "clientes", idCliente);
        const imgName = ref(storage, `images/${idCliente}/${name}`);
        deleteObject(imgName).then(() => {
            updateDoc(userDoc, {
                urlFotos: arrayRemove({name: name, urls: url})
            });
            const newList = imageUrls.filter((item) => item.name !== name);
            setImageUrls(newList);
            
            alert("Imagem deletada com sucesso!");

        }).catch((error) => {
            console.log(error);
        });
        setAlertContent(null);
    };

    function handdleMessage (e) {
    
        e.preventDefault();

        async function updateStatus (){
            const userDoc = doc(db, "clientes", idCliente);
            await updateDoc(userDoc, {message: messageCarousel});
        };
        updateStatus();
        setShowToast(true);
        e.target.reset();
    };

    const paymentUpdates = async (foto, vela, plano, vaso) => {

        if (idCliente){

            const userDoc = doc(db, "clientes", idCliente)
            const docSnap = await getDoc(userDoc); 
            const refFotos = docSnap.data().qtdFotos;
            const refDataVenc = docSnap.data().dataVencimento.toDate();
            const refVela = docSnap.data().homenagem.vela.dataVencimento.toDate();
            const refVaso = docSnap.data().homenagem.vaso.dataVencimento.toDate();
            
            
            Date.prototype.addDays = function(days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }
            
            if(foto !== null){   
                const newFotos = refFotos + 2;
                updateDoc(userDoc, {
                    qtdFotos: newFotos
                });
            };
            if(plano !== null){
                let vencimento = refDataVenc.addDays(366);
                updateDoc(userDoc, {
                    dataVencimento: vencimento
                });
            };
            if(vaso){ 
                let vencimento = refVaso.addDays(7);
                updateDoc(userDoc, {
                    "homenagem.vaso.ativo": true,
                    "homenagem.vaso.dataVencimento": vencimento
                });
            };
            if(vela){
                let vencimento = refVela.addDays(7);
                updateDoc(userDoc, {
                    "homenagem.vela.ativo": true,
                    "homenagem.vela.dataVencimento": vencimento
                });
            };
            console.log("Atualizei cliente com info de pagto")
        };
    };
    
    return(
        <clientesData.Provider value={ {

            //STATES
            imageUrls, setImageUrls,
            conteudo, setConteudo,
            pagAtual, setPagAtual,
            alertContent, setAlertContent,
            qtdFotos,
            idCliente, setIdCliente,
            setImageUpload,
            dataVencimento,
            paymentPage,
            pagClient,
            messageCarousel, setMessageCarousel,
            showToast, setShowToast,
            vasoVisability,
            velaVisability,
            
            //FUNCTIONS
            uploadFile,
            deleteImage,
            getInfos,
            getPaymentInfos,
            handdleMessage,
            paymentUpdates

        } }> {children} </clientesData.Provider>
    );
};


export {ClientesDataContextProvider, useClientesData};