//CSS
import "pages/private/dashboard/pages/listarLinks/listarLinks.css";
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import {useClientesContext} from 'pages/private/dashboard/context/AuthClientesHandlerContext';
import ReactPaginate from "react-paginate"
import { RiDeleteBin5Fill } from "react-icons/ri";
import { ImQrcode } from "react-icons/im";
import PrintModal from './components/PrintModal';

//<--PAGE
function ListarUsuarios() {

  //<--STATES
  const {
    usuarios, 
    attLista, 
    deletarUsuario, 
    show, 
    setShow, 
    nCadAtual, 
    setNCadAtual, 
    setIdAtual,
    setQrPrint
  } = useClientesContext();
  
  const [pageNumber, setPageNumber] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  

  //<--OTHER VARS
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(usuarios.length / usersPerPage);

  //<--FUNCTIONS
  const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

  const displayUsers = usuarios.slice(pagesVisited, pagesVisited + usersPerPage).map((usuario) => {
    return (
      
      <tr key={usuario.id}>
        <td>{usuario.sku} </td>
        <td>{usuario.linkQrCode}</td>
        <td><ImQrcode className='btnList' size={19} onClick={() => modalPrint(usuario.linkQrCode)}/></td>
        <td><RiDeleteBin5Fill onClick={() =>{showModal(usuario.id, usuario.sku)}} className='btnList' size={20} /></td>
      </tr>
    )
  })

  const modalPrint = (link) => {
    setQrPrint(link);
    setModalShow(true);
  }

  const handleClose = () => {
    setIdAtual("");
    setNCadAtual("");
    setShow(false);
  };

  const showModal = (id, sku) => {
    setIdAtual(id);
    setNCadAtual(sku);
    setShow(true);
  };

//START UI  

  return (
    <>
    
      <PrintModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Deletar usuários</Modal.Title>
      </Modal.Header>
      <Modal.Body>Deseja mesmo deletar link de n° {nCadAtual}?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Não
        </Button>
        <Button variant="primary" onClick={()=>{deletarUsuario();}}>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>


    <div className="internoLisarLinks">
      <h4>Lista de Usuários:</h4>
      <br />
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>N° Cad</th>
            <th>URL Qr Code</th>
            <th>Print</th>
            <th>Deletar</th>
          </tr>
        </thead>
        <tbody>
          {displayUsers}
        </tbody>
      </Table>
      
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={changePage}
          previousLabel={"Ant"}
          previousLinkClassName={"previousBttn"}
          nextLabel={"Seg"}
          nextLinkClassName={"nextBttn"}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          className={"paginationBttnsContainer"}
          pageClassName={"pageBttn"}
          disabledLinkClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          breakLabel={null}
          
        />
      

      <Button className='btnAttList' onClick={attLista}>Atualizar Lista</Button>
    
    </div>
  </>
  );
};

export default ListarUsuarios;

