import {useEffect, useRef} from 'react'
import  QRCode  from 'qrcode'

export default function QrCodeCanvas({link, width}) {
    const canvasRef = useRef();
    
    useEffect(() =>{
        QRCode.toCanvas(canvasRef.current, link, {width: width}, (error) =>{

        });

    }, [link]);


  return (
    <div>
    <canvas ref={canvasRef} id="canavs"></canvas>
    </div>
  );
};