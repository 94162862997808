//OTHER LOBS
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function ResetPasswordModal(props) {

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Email enviado com sucesso!
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Verifique sua caixa de email para redefinir sua senha. Caso não tenha recebido, verifique a caixa de Lixo eletronico. </p>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
};
