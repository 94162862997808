//REACT LIB
import { createContext, useContext, useState, useEffect } from "react"; 
//FIREBASE
import { auth } from "config/Firebase-config";
import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail} from "firebase/auth";

//<--CONTEXT HOOK
const userAuthContext = createContext();
function useUserAuth(){
    return useContext(userAuthContext);
};
//-->CONTEXT HOOK

//<--PROVIDER
function UserAuthContextProvedor( {children} ){

    //<--STATES
    const [user, setUser] = useState("");

    //<--FUNCTIONS
    
    function logIn (email, password){
        return signInWithEmailAndPassword (auth, email, password);
    };

    function resetPassword (email){
        return sendPasswordResetEmail(auth, email);
    };

    useEffect(()=>{
        const unsubscribre = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => {
            unsubscribre();
        };
    }, []);

    return(
        <userAuthContext.Provider value={ {

            //STATES
            user, setUser, 

            //FUNCTIONS
            logIn, 
            resetPassword

        } }> {children} </userAuthContext.Provider>
    );
};

export {UserAuthContextProvedor, useUserAuth};
