//CSS
import "pages/public/profile/profile.css";
//REACT LIB
import { useEffect } from "react";
//OTHER LIBS
import Nav from "react-bootstrap/Nav";
//CONTEXTS
import { useClientesData } from "pages/public/profile/context/PublicClientesDataContext";
//PAGES
import Homenagem from "pages/public/profile/pages/homenagem/Homenagem";
import CarouselPage from "pages/public/profile/pages/carousel/CarouselPage";
import Configuracoes from "pages/public/profile/pages/configuracoes/Configuracoes";


export default function ProfilePage() {
  
  const { getInfos, conteudo, setConteudo } = useClientesData();

  const pagHomenagem = () => {
    setConteudo(<Homenagem />);
  };

  const pagFotos = () => {
    setConteudo(<CarouselPage />);
  };

  const pagConfig = () => {
    setConteudo(<Configuracoes />);
  };

  useEffect(() => {
    getInfos();
  }, []);

  return (
    <>
    <div className="profileContainer">
    <Nav variant="tabs" defaultActiveKey="home" className="navBar">
      <Nav.Item>
        <Nav.Link eventKey="home" onClick={pagHomenagem}>
          Homenagem
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="fotos" onClick={pagFotos}>
          Fotos
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link eventKey="config" onClick={pagConfig}>
          Configurações
        </Nav.Link>
      </Nav.Item>
    </Nav>

    <div className="p-4 pb-3 contentContainer">
      {conteudo}
    </div>
    </div>
    </>
  );
};