//CSS
import "pages/public/profile/pages/homenagem/homenagem.css";
//OTHER LIBS
import Lottie from "lottie-react";
import fireAnimation from "images/fire.json";
import beesAnimation from "images/bees.json";
//CONTEXT
import {useClientesData} from 'pages/public/profile/context/PublicClientesDataContext';



export default function Homenagem() {
  const {
    vasoVisability,
    velaVisability,
    imageUrls,
    paymentPage
  } = useClientesData();

  const style = 
    imageUrls.length > 0 ? 
      {backgroundImage: `url(${imageUrls[0].urls})`} : 
      {backgroundImage: `url(${require('images/whiteBack.jpg')})`};
    

  return (
    <div className="p-4 pb-3 boxProfileHomen">
      
      <div className="objectsContainter">
     
        <a className="btnAnimacoes" href={paymentPage}>Comprar</a>
        <div className="portrait" style={style}>
          <img src={require('images/portrait2.png')} alt="portrait" />
        </div>
        <div className="vasoContainer">
          {vasoVisability ? 
          <>
          <div className="beesAnimation">
            <Lottie animationData={beesAnimation} loop={true}   />
          </div>
            <img src={require('images/vaseFlower.png')} alt="vase" />
          </>: 
            <img src={require('images/vaseNoFlower.png')} alt="vase" /> 
          }
        </div>
        {velaVisability ? 
        <>
        <div className="vela">
          <img src={require('images/candleNoFireWhite.png')} alt="fire" /> 
        </div>
        <div className="fire">
          <Lottie animationData={fireAnimation} loop={true}  />
        </div>
        </>: 
        <>
        <div className="vela">
           <img src={require('images/candleNoFireWhite.png')} alt="fire" /> 
        </div>
        </> }
        
      </div>
    </div>
  );
};

 