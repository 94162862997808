//CSS
import "routes/app.css";
//REACT LIB
import React from "react";
//OTHER LIBS
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//CONTEXTS
import { VerificaUser } from "routes/Autenticar";
import { UserAuthContextProvedor } from "pages/public/login/context/AdminContext";
import { ClientesDataContextProvider } from "pages/public/profile/context/PublicClientesDataContext";
//PAGES
import Login from "pages/public/login/Login";
import Admin from "pages/private/Admin";
import Profile from "pages/public/profile/Profile";
import ErrorPage from "pages/public/404/ErrorPage";
import Payment from "pages/public/profile/pages/configuracoes/payment/Payment";
import Success from "pages/public/profile/pages/configuracoes/payment/success/Success";
import Cancel from "pages/public/profile/pages/configuracoes/payment/cancel/Cancel";

export default function App() {
  
  return (
    <UserAuthContextProvedor>
    <ClientesDataContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/admin"
            element={
              <VerificaUser>
                <Admin />
              </VerificaUser>
            }
          />
          <Route path="/profile/:username" element={<Profile />} />
          <Route path="/profile/:username/payment" element={<Payment />} />
          <Route path="/profile/:username/success" element={<Success />} />
          <Route path="/profile/:username/cancel" element={<Cancel />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </ClientesDataContextProvider>
    </UserAuthContextProvedor>
  );
};