//CSS
import "pages/public/profile/pages/configuracoes/payment/cancel/cancel.css";
//REACT LIB
import {useEffect, useState} from 'react'
//OTHER LIBS
import { Link } from 'react-router-dom';
import { TbFaceIdError } from "react-icons/tb";


export default function Cancel() {

    const [id, setId] = useState("");

    useEffect(() => {

        setId(localStorage.getItem("ID_CLIENTE"));

        localStorage.removeItem("FOTO_STATUS");
        localStorage.removeItem("VELA_STATUS");
        localStorage.removeItem("VASO_STATUS");
        localStorage.removeItem("PLANO_STATUS");
        localStorage.removeItem("ID_CLIENTE");
        
      }, []);

  return (
    <div className='paymentInfoText'>
        <TbFaceIdError size={150}/>
        <h1>Seu pagamento não foi processado.</h1>
        <br />
        <p>Volte para a sua página e tente novamente.</p>
        <Link className='btnCancel' to={`/profile/${id}`}>
          Voltar
        </Link>
    </div>
  );
};