//REACT LIB
import { createContext, useContext, useEffect, useState } from 'react'
import QrCodeCanvas from '../components/QrCodeCanvas';
import { signOut } from "firebase/auth";
import { db, auth, storage } from 'config/Firebase-config';
import { 
    collection, 
    getDocs, 
    doc, 
    getDoc, 
    deleteDoc, 
    addDoc,
    query,
    where,
    orderBy,
    updateDoc 
    
} from "firebase/firestore";
import { ref, deleteObject, listAll } from "firebase/storage";

//<--USE CONTEXT
const clientesContext = createContext();
function useClientesContext(){
    return useContext(clientesContext);
};

//<--PROVIDER
function ClientesHandlerContextProvider({children}) {

    //<--STATES
    const [qtdUsuarios, setQtdUsuarios] = useState(0);
    const [novoSku, setNovoSku] = useState(0);
    const [qrPrint, setQrPrint] = useState("");
        //TO LISTAR LINKS
    const [usuarios, setUsuarios] = useState([]);    
    const [show, setShow] = useState(false);
    const [idAtual, setIdAtual] = useState('');
    const [nCadAtual, setNCadAtual] = useState(''); 
        //TO CRIAR LINKS
    const [idClienteLink, setIdClienteLink] = useState('');
    const [qrLink, setQrLink] = useState('');
    const [qrCodeContent, setQrCodeContent] = useState(null);

    //<--DB REF VARS
    const clientesRef = collection(db, "clientes");
    const skuRef = doc(db, "n_clientes", "Toux5Y7aFx0D2PgM2qxs");

    //DATE HANDDLING
    
    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    
    const dataAtual = new Date();
    let vencimento = dataAtual.addDays(366);
    let vencimentoHomenagem = dataAtual.addDays(366);
    

    //<--FUNCTIONS
    const getClientes = async () => {
        const q = query(clientesRef, orderBy("sku", "asc"));
        const data = await getDocs(q);
        setUsuarios(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        setQtdUsuarios(data.docs.length);
        const docSnap = await getDoc(skuRef);
        const skuData = docSnap.data().Sku;
        setNovoSku (skuData);  
        console.log("Eu puxei dados da db");
    };

    const deletarUsuario = async () => {
    
      const userDoc = doc(db, "clientes", idAtual);
      const docSnap = await getDoc(userDoc);
      const fotosUser = docSnap.data().urlFotos;
      
      if(fotosUser.length > 0){
        const imageRef = ref(storage, `images/${idAtual}`)
        listAll(imageRef).then((res) => {
            res.items.forEach((image) => { 
                const deleteItem = ref(storage, `images/${idAtual}/${image.name}`);
                deleteObject(deleteItem);
            });
        });
      };
    
      await deleteDoc(userDoc);
      setIdAtual("");
      setNCadAtual("");
      setShow(false);
      attLista();
      
    };

    function attLista (){
        getClientes();
    };

    const criarUsuario = async () => {
      
        await addDoc(clientesRef, { 
          ref: "last User",
          sku: novoSku,
          active: true,
          linkQrCode: '',
          paymentLink: '',
          urlFotos:[],
          qtdFotos: 3,
          firstAccess: true,
          dataVencimento: vencimento,
          message: '',
          homenagem: {
            vaso: {
              ativo: true,
              dataVencimento: vencimentoHomenagem,
            },
            vela: {
              ativo: true,
              dataVencimento: vencimentoHomenagem,
            }
          },
        });
        updateDoc(skuRef, {Sku: novoSku + 1});
        linkCliente();
        
        console.log("Adicionei novo cliente")
    };
    
    const linkCliente = async() =>{
        
        const q = query(clientesRef, where("ref", "==", "last User"));
        const querySnapshot = await getDocs(q);
            querySnapshot.forEach( async (cliente) => {
            setIdClienteLink(cliente.id);
            const clienteDoc = doc(db, "clientes", cliente.id);
            await updateDoc(clienteDoc, {linkQrCode: window.location.origin + "/profile/" + cliente.id});
            await updateDoc(clienteDoc, {ref: ""});
            setQrLink(window.location.origin + "/profile/" + cliente.id);
            setQrCodeContent(<QrCodeCanvas link={ window.location.origin + "/profile/" + cliente.id} width={300} />);
            setNovoSku(novoSku + 1);
            });
    };
    function deslogarUser (){
        return signOut(auth);
    };

    //FAKE DB LISTNER
    useEffect(() => {
        getClientes();
     }, [novoSku])

    return(
        <clientesContext.Provider value={ {

            //STATES
            usuarios, setUsuarios,
            novoSku, setNovoSku,
            show, setShow,
            nCadAtual, setNCadAtual,
            idAtual, setIdAtual,
            qtdUsuarios,
            idClienteLink,
            qrLink,
            qrCodeContent,
            qrPrint, setQrPrint,

            //FUNCTIONS
            getClientes, 
            deletarUsuario,
            attLista,
            criarUsuario,
            deslogarUser
        
        } }> {children} </clientesContext.Provider>
    );
};


export {ClientesHandlerContextProvider, useClientesContext};

