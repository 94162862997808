//REACT LIB
import React from 'react';
import ReactDOM from 'react-dom/client';
//OTHER LIBS
import 'bootstrap/dist/css/bootstrap.min.css';
//FROM PROJECT
import App from 'routes/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);