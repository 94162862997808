//<-- IMPORTS
import {createContext, useContext, useState} from 'react'

//<--USE CONTEXT
const conteudoDash = createContext();

function useConteudoDash(){
    return useContext(conteudoDash);
};

//<--PROVIDER
function ConteudoDashContextProvider({children}) {

    //<--STATES
    const [content, setContent] = useState(
        <div>
            <br/>
            <h1>Memories Code</h1>
            <br />
            <h4><strong>Seja bem vindo!!</strong></h4>
            <div>
                
                <br />
                <div>Para criar um novo qrCode, utilize o menu a esquerda em <strong>"Criar Link"</strong></div>
                <div>Se deseja visualizar os qrCodes já criados, utilize o menu a esquerda em <strong>"Listar Links"</strong></div>
                <div>No rodapé desta página vc consegue ver a quantidade de usuarios criados e o limite para o seu atual</div>
            </div>
            <br />
            <h3>Memoriescode.com. Suas memórias eternizadas na web!</h3>
        </div>
        );

    return(
        <conteudoDash.Provider value={{ 

            //STATES
            content, setContent 

        }}> {children} </conteudoDash.Provider>
    );
};

export {ConteudoDashContextProvider, useConteudoDash};