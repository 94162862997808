//CSS
import "pages/public/profile/pages/carousel/carouselPage.css";
//OTHER LIBS
import {Carousel} from 'react-bootstrap';
//CONTEXT
import {useClientesData} from 'pages/public/profile/context/PublicClientesDataContext';


export default function CarouselPage() {

    const {imageUrls, messageCarousel} = useClientesData();
   
    return (
        <div className="p-4 pb-3 carouselContainer">
            <Carousel fade variant='dark'>
                {imageUrls.map((url) => { return (
                    <Carousel.Item key={url.name}>
                        <img className="d-block imgCarousel" src={url.urls} />
                    </Carousel.Item>
                )})}
            </Carousel>
            <div className="mensageCarousel">
                {messageCarousel}
            </div>
        </div>
    );
};