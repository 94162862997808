//CONTEXTS
import {ClientesHandlerContextProvider} from "pages/private/dashboard/context/AuthClientesHandlerContext";
import {ConteudoDashContextProvider} from "pages/private/dashboard/context/ConteudoDashContext";
//PAGES
import Dashboard from 'pages/private/dashboard/Dashboard';


export default function Admin() {
    return (
        <ClientesHandlerContextProvider>
        <ConteudoDashContextProvider>  
            <Dashboard />
        </ConteudoDashContextProvider>  
        </ClientesHandlerContextProvider>
    );
};