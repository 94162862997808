//CSS
import "pages/private/dashboard/pages/criarLinks/criarLinks.css";
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { BsFillPrinterFill } from "react-icons/bs";
import { ImQrcode } from "react-icons/im";
import ReactToPrint from "react-to-print";
import { useClientesContext } from "pages/private/dashboard/context/AuthClientesHandlerContext";

//<--PAGE
function CriarLinks() {
  
  const { criarUsuario, qrLink, qrCodeContent } = useClientesContext();
  const componentRef = useRef();

  return (

    <div className="internoCriarLinks">
      <div ref={componentRef}>{qrCodeContent}</div>
      <div>
        <strong> Link da página: </strong>
        {qrLink}
      </div>
      <br />
      <div className="btnCriarLinkContainer">

        <Button onClick={criarUsuario}>
          Criar <ImQrcode size={18} />
        </Button>

        <ReactToPrint
          trigger={() => (

            <div className="btnPrint">
              <BsFillPrinterFill size={20} />
            </div>

          )}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
}

export default CriarLinks;
