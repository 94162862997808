//OTHER LIBS
import { Navigate } from 'react-router-dom';
//CONTEXT
import { useUserAuth } from 'pages/public/login/context/AdminContext';


export const VerificaUser = ({children}) => {

    const {user} = useUserAuth();

    if(!user){
        return <Navigate to="/"/>;
    };

    return children
};