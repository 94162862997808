//CSS
import "pages/private/dashboard/dashboard.css";
import { Button, ProgressBar } from 'react-bootstrap';
import {useClientesContext} from 'pages/private/dashboard/context/AuthClientesHandlerContext';
import { useNavigate } from 'react-router-dom';
import {useConteudoDash} from 'pages/private/dashboard/context/ConteudoDashContext';
import ListarUsuarios from 'pages/private/dashboard/pages/listarLinks/ListarLinks';
import CriarLinks from 'pages/private/dashboard/pages/criarLinks/CriarLinks';


//-->IMPORTS

function Dashboard() {

const navigate = useNavigate();        
const { content, setContent } = useConteudoDash();
const { qtdUsuarios, deslogarUser } = useClientesContext();



const handdleLogOut = (user) =>{
    deslogarUser(user);
    navigate("/");
};

const pagCriarUsers = (content) =>{
    setContent(<CriarLinks />);
};

const pagListarUsers = (content) =>{
    setContent( <ListarUsuarios /> );
};


//<-- Start UI
    return (
        <>

        <div className='dashContainer'>
            
            <div className='sideBarMenu'>
                
                <Button className="btnMenu" onClick={pagCriarUsers} > Criar Link </Button>
                <Button className="btnMenu" onClick={pagListarUsers} > Listar Links </Button>
                
            </div>
            <div>
                <div className="contentPage">
                    {content}
                </div>
                <div className="footer">
                    <div className='progBar'>
                        <ProgressBar animated now={(qtdUsuarios*100)/1665} />
                        <p>{qtdUsuarios} / 1665 - Usuários Criados</p>
                    </div>
                    <Button className="btnFooter" onClick={handdleLogOut}> Logout </Button>
                </div>
            </div>
        </div>


        </>
    );
};

//--> End UI

export default Dashboard



