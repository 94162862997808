//CSS
import "pages/public/404/errorPage.css";
//OTHER LIBS
import { BsConeStriped } from "react-icons/bs";

export default function ErrorPage() {

    return (
        <div className="errorContainer">
            <BsConeStriped size={200}/>
            <br />
            <h1>PAGE NOT FOUND</h1>
        </div>
    );
};