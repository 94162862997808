//CSS
import "pages/public/profile/pages/configuracoes/configuracoes.css";
//OTHER LIBS
import { InputGroup, Button, FormControl } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
//CONTEXT
import { useClientesData } from "pages/public/profile/context/PublicClientesDataContext";


export default function UploadImages() {

  const {
    setImageUpload,
    uploadFile,
    imageUrls,
    deleteImage,
    alertContent,
    setAlertContent,
    dataVencimento,
    qtdFotos,
    paymentPage,
    handdleMessage,
    setMessageCarousel,
    showToast,
    setShowToast
  } = useClientesData();

  const date = dataVencimento.toDate().toLocaleDateString("pt-BR");

  function handleChange(e) {
    setImageUpload(e);
    setAlertContent(null);
  }

  return (

    <div className="boxProfileConfig">
      <div className="boxProfileContentConfig">
        <InputGroup className="mb-3">
          <FormControl
            type="file"
            onChange={(event) => handleChange(event.target.files[0])}
          />
          <Button
            variant="outline-secondary"
            id="button-addon1"
            onClick={uploadFile}
          >
            Subir Imagem
          </Button>
        </InputGroup>
        <p className="textPorta">*A primeira imagem será a do porta retrato</p>

        {alertContent}

        <div className="imgDelContainer">
          {imageUrls.map((url) => {
            return (
              <div className="imgDel" key={url.name}>
                <img src={url.urls} />
                <button
                  onClick={deleteImage.bind(deleteImage, url.name, url.urls)}
                >
                  Deletar
                </button>
              </div>
            )
          })}
        </div>
      </div>
      <div className="messageCarousel">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={2000}
            autohide
            className="toast"
          >
            <Toast.Body>Mensagem adicionada com sucesso!</Toast.Body>
          </Toast>

          <form className="messageCarouselForm" onSubmit={handdleMessage}>
            <div>Deixe sua mensagem:</div>
            <input
              type="text"
              placeholder="Mensagem"
              onChange={(e) => {
                setMessageCarousel(e.target.value);
              }}
            />
            <Button className="btnCarouselForm" type="submit">
              Adicionar
            </Button>
          </form>
        </div>

      <div className="boxProfileFooter">
        <span>
          Se desejar novas fotos ou extender o seu plano, clique 
          <a href={paymentPage}>AQUI</a>
        </span>
        <hr/>
        <p className="emailText">Dúvidas: contatomemoriescode@gmail.com</p>

        <div className="configRodape">
          <p>Data de Vencimento: {date}</p>
          <p>Fotos restantes: {qtdFotos - imageUrls.length}</p>
        </div>
        
      </div>
    </div>
  );
};