//CSS
import "pages/public/profile/pages/configuracoes/payment/success/success.css";
//REACT LIB
import { useEffect, useState } from "react";
//OTHER LIBS
import { Link } from "react-router-dom";
import { ImCheckboxChecked } from "react-icons/im";
//CONTEXT
import { useClientesData } from "pages/public/profile/context/PublicClientesDataContext";

export default function Success() {
  const { idCliente, setIdCliente, paymentUpdates } = useClientesData();

  const [foto, setFoto] = useState(null);
  const [vela, setVela] = useState(null);
  const [plano, setPlano] = useState(null);
  const [vaso, setVaso] = useState(null);

  useEffect(() => {
    setFoto(localStorage.getItem("FOTO_STATUS"));
    setVela(localStorage.getItem("VELA_STATUS"));
    setPlano(localStorage.getItem("PLANO_STATUS"));
    setVaso(localStorage.getItem("VASO_STATUS"));
    setIdCliente(localStorage.getItem("ID_CLIENTE"));

    localStorage.removeItem("FOTO_STATUS");
    localStorage.removeItem("VELA_STATUS");
    localStorage.removeItem("VASO_STATUS");
    localStorage.removeItem("PLANO_STATUS");
    localStorage.removeItem("ID_CLIENTE");
  }, []);

  paymentUpdates(foto, vela, plano, vaso);

  return (

    <div className="paymentInfoText">
      <ImCheckboxChecked size={150} className="checkSucces" />
      <br />
      <h1>Muito obrigado por sua compra</h1>
      <br />
      <div>
        <h3>Você acabou de adiquirir:</h3>
        <br />
        {foto && 
          <div className="textSucces">
            <ImCheckboxChecked size={20} className="checkSucces" />
            2 fotos extras para sua página.
          </div>
        }
        {plano &&
          <div className="textSucces">
            <ImCheckboxChecked size={20} className="checkSucces" />
            1 ano de prazo para o vencimento da sua página.
          </div>
        }
        {vela &&
          <div className="textSucces">
            <ImCheckboxChecked size={20} className="checkSucces" /> 
            Acender a vela em sua página de homenagem.
          </div>
        }
        {vaso &&
          <div className="textSucces">
            <ImCheckboxChecked size={20} className="checkSucces" />
            Adicionar uma flor ao vaso da sua página de homenagem.
          </div>
        }
      </div>
      <br />
      <Link className="btnCancel" to={`/profile/${idCliente}`}>
        Voltar
      </Link>
    </div>
  );
};