import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { BsFillPrinterFill } from "react-icons/bs";
import {useClientesContext} from 'pages/private/dashboard/context/AuthClientesHandlerContext';
import QrCodeCanvas from 'pages/private/dashboard/components/QrCodeCanvas';


export default function PrintModal(props) {
    const { qrPrint } = useClientesContext();
    const componentRef = useRef();
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Imprimir Qr Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={componentRef} className="qrPrintScreen">
          
          <QrCodeCanvas 
            link={qrPrint} 
            width={400}
          />

          <p>{qrPrint} </p>
           
         
          </div>
        </Modal.Body>
        <Modal.Footer>
        <ReactToPrint trigger={() => <div className='btnPrint'><BsFillPrinterFill className='btnPrint2' size={20}/></div>} content={() => componentRef.current} />
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  