//CSS
import "pages/public/login/login.css";
//REACT LIB
import { useState } from "react";
//OTHER LIBS
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
//CONTEXTS
import { useUserAuth } from "pages/public/login/context/AdminContext";
//PAGES
import ResetPasswordModal from "pages/public/login/components/ResetPassModal";

export default function LoginPage() {

  const navigate = useNavigate();
  const { logIn, resetPassword } = useUserAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await logIn(email, password);
      navigate("/admin");

    } catch (err) {

      switch (err.message) {
        case "Firebase: Error (auth/invalid-email).":
          setError("Email inválido");
          break;
        case "Firebase: Error (auth/user-not-found).":
          setError("Email não cadastrado");
          break;
        case "Firebase: Error (auth/wrong-password).":
          setError("Senha incorreta");
          break;

        default:
          setError(err.message);
      };

      console.log(err.message);
    };
  };

  const handdleResetEmail = async (e) => {
    try {
      await resetPassword(email);
      setModalShow(true);

    } catch (err) {

      switch (err.message) {
        case "Firebase: Error (auth/missing-email).":
          setError("Preencha o campo email");
          break;
        case "Firebase: Error (auth/too-many-requests).":
          setError("Muitas tentativas. Aguarde para tentar novamente");
          break;
        case "Firebase: Error (auth/user-not-found).":
          setError("Email não cadastrado");
          break;
        case "Firebase: Error (auth/invalid-email).":
          setError("Email não cadastrado");
          break;

        default:
          setError(err.message);
      };

      console.log(err.message);
    };
  };

  return (
    <>
    <div className="p-4 pb-3 box">
      <h2 className="mb-3 text-center">Painel de Controle</h2>

      <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 pt-2" controlId="formBasicEmail">
            <Form.Control
                placeholder="Digite o seu email..."
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
            />
          </Form.Group>

          <Form.Group className="mb-3 pb-2" controlId="formBasicPassword">
            <Form.Control
                placeholder="Digite o seu password..."
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
            />
          </Form.Group>

          <div className="d-grid gap-2">
              <Button variant="primary" type="submit" className="bnt">
                  <b>Log In</b>
              </Button>
          </div>
      </Form>
      <hr />

      <p className="mt-3 text-center">
          Esqueceu sua senha? Clique
          <a className="link" onClick={handdleResetEmail}> aqui </a>
          para redefinir.
      </p>

      {error && 
          <Alert className="mt-3 mb-0" variant="danger">
              {error}
          </Alert>
      }
    </div>

    <ResetPasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
    />
    </>
  );
};